import { Col, Container, Image, Row } from 'react-bootstrap';
import shape12 from '../assets/images/bg/shape-12.png';
import sliderShape2 from '../assets/images/shape/slider-shape-2.png';
import boxShape2 from '../assets/images/shape/box-shape-2.png';
import vector2 from '../assets/images/vector/vector-2.png';
import vector3 from '../assets/images/vector/vector-3.png';

import { Link } from 'react-router-dom';

const HomeBanner = () => {
  return (
    <div
      className="hero-area hero-2-before hero-bg"
      style={{ backgroundImage: `url(${shape12})` }}
    >
      <svg
        className="hero-wave"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
          className="shape-fill"
        ></path>
      </svg>
      <Image src={sliderShape2} alt="no image" className="hero-2-shape-1" />
      <Image src={boxShape2} alt="no image" className="hero-2-shape-2" />
      <div className="hero-single-2">
        <Container>
          <Row>
            <Col xl="5">
              <div className="hero-content element-center">
                <div className="hero-content-desc">
                  <h1 className="hero-sub-title mb-4">
                    Welcome to Ingen Techno
                  </h1>
                  <h2 className="hero-title">
                    Our algorithm lead you to success
                  </h2>
                  <p className="mb-5">
                    We provide innovative and intelligent technology solutions
                    and services. When it comes to interactive marketing, we've
                    got u covered. Be where the world is going.
                  </p>
                  <div className="hero-btn">
                    <Link href="contact.html" className="btn-1 btn-gradient">
                      {' '}
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl="7">
              <div className="left-hdr-pic pos-rel text-center pl-60">
                <Image src={vector2} alt="no image" className="vector-2" />
                <Image src={vector3} alt="no image" className="vector-3" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default HomeBanner;
