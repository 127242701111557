import './Style.css';
import About from '../components/About';
import OurServices from '../components/OurServices';
import ChooseUs from '../components/ChooseUs';
import ContactUs from '../components/ContactUs';
import OurFeatures from '../components/OurFeatures';
import HomeBanner from '../components/HomeBanner';
import Promo from '../components/Promo';

const Index = () => {
  return (
    <main className="main">
      <HomeBanner />
      <About />
      <Promo />
      <OurServices />
      <ChooseUs/>
      <ContactUs />
      <OurFeatures />
    </main>
  );
};

export default Index;
