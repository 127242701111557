import { Link } from 'react-router-dom';
import { Container, Image, Button, Row, Col, Form } from 'react-bootstrap';
import './Footer.css';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaPhone,
  FaEnvelope,
} from 'react-icons/fa6';
import bgLayered from '../assets/images/bg/bg-layered.png';
import shape14 from '../assets/images/shape/shape-14.png';

const Footer = () => {
  return (
    <>
      <footer className="footer footer-wave bg-dark-blue text-light de-padding pos-rel overflow-hidden hero-bg">
        <Image src={bgLayered} className="footer-shape" />
        <Image src={shape14} className="shape-14" />
        <svg
          className="wave"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
        <Container>
          <Row className="g-5">
            <Col xl="7" lg="6" md="6">
              <Row>
                <Col xl="6" lg="6" md="6">
                  <div className="footer-contact">
                    <h4>Contact Us</h4>
                    <div className="contact-dtls">
                      <p>
                        3rd Floor Maharani Complex,
                        <br />
                        Near Hari Om Petrol Pump,
                        <br />
                        Anisabad, Patna-800002.
                      </p>
                      <Link>
                        <p>
                          <FaEnvelope /> info@ingentechno.com
                        </p>
                      </Link>
                      <Link>
                        <p>
                          <FaPhone /> +91 8290997456
                        </p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col xl="6" lg="6" md="6">
                  <div className="footer-contact">
                    <h4>Follow Us</h4>
                    <div className="social-media d-flex align-items-center">
                      <Link>
                        <h3 className="social-ico">
                          <FaFacebookF />
                        </h3>
                      </Link>
                      <Link>
                        <h3 className="social-ico">
                          <FaInstagram />
                        </h3>
                      </Link>
                      <Link>
                        <h3 className="social-ico">
                          <FaYoutube />
                        </h3>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="subscribe-area mt-50">
                <Form>
                  <Form.Group className="d-flex align-items-center formGrp">
                    <Form.Control
                      type="text"
                      className="input-style-4"
                      placeholder="Add your mail.."
                    ></Form.Control>
                    <Button type="submit" className="btn-1 btn-circle btn-sm">
                      Connect
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </Col>
            <Col xl="5" lg="6" md="6">
              <Row>
                <Col xl="6" lg="6" md="6">
                  <div className="footer-widget footer-link">
                    <h4 className="footer-widget-title">Useful Link</h4>
                    <ul className="footer-list ps-0">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/services">Our Services</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xl="6" lg="6" md="6">
                  <div className="footer-widget footer-link">
                    <h4 className="footer-widget-title">We Provide</h4>
                    <ul className="footer-list ps-0">
                      <li>
                        <Link to="">Human Resource</Link>
                      </li>
                      <li>
                        <Link to="">Business Management </Link>
                      </li>
                      <li>
                        <Link to="">Technical Solutions</Link>
                      </li>
                      <li>
                        <Link to="">E-commerce</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="copyright">
          <Container>
            <div className="copyright-element d-flex align-items-center justify-content-between">
              <div className="footer-logo">
                <Image src="" alt="logo" />
              </div>
              <p className="mb-0">
                Copyright © 2024. All Rights Reserved Ingen Techno
              </p>
              <p className="mb-0">Created by In-gen Techno pvt. ltd.</p>
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
};

export default Footer;
